<template>
  <div class="header-spacing">
    <!-- mission section start -->
    <section class="mission-wrap">
      <div class="container">
        <div class="mission-content-wrap">
          <div class="mission-info">
            <h6 class="secondary-text fw-400">About Us</h6>
            <h2 class="small font-fahkwang fw-700 text-capitalize aboutext">Our Mission</h2>
            <p class="text-gray">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
            </p>
            <p class="text-gray">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim
            </p>
          </div>
          <div class="mission-content">
            <div>
              <div class="mission-icon">
                <img src="../assets/images/icons/people.svg" alt="people" />
              </div>
              <p class="text-gray page-title-label mb-0">
                Build a community of SaaS developers that are actively consuming and contributing to SaaS architecture
                and developement practices.
              </p>
            </div>
            <div>
              <div class="mission-icon">
                <img src="../assets/images/icons/monitor-mobbile.svg" alt="monitor-mobbile" />
              </div>
              <p class="text-gray page-title-label mb-0">
                Enable SaaS builders to easily discover and consume existing SaaS best practices.
              </p>
            </div>
            <div>
              <div class="mission-icon">
                <img src="../assets/images/icons/mirroring-screen.svg" alt="mirroring-screen" />
              </div>
              <p class="text-gray page-title-label mb-0">
                Provide channels and mechanisms for the community to discuss and contribute SaaS content that advances
                and extends the overall collection of practices.
              </p>
            </div>
            <div>
              <div class="mission-icon">
                <img src="../assets/images/icons/airdrop.svg" alt="airdrop" />
              </div>
              <p class="text-gray page-title-label mb-0">
                Be the place where SaaS builders come to consume and engage on SaaS topics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- mission section end -->
    <!-- explore-techniques section start -->
    <section class="explore-techniques">
      <div class="container">
        <div class="row">
          <div class="col-xl-10 mx-auto">
            <div class="explore-tech-content">
              <h2 class="small text-center font-fahkwang fw-700">
                Explore technical topics and other disciplines across 170+ Q&A communities
              </h2>
              <p class="d-inline-block text-center white mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.
              </p>
              <div class="d-flex justify-content-center">
                <router-link to="/all-questions" class="custom-btn transparent-btn mx-auto white">View All</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- explore-techniques section end -->

    <!-- ABOUT SAAS BUILDER -->
    <section class="about-saas space-120">
      <div class="about-saas-content">
        <div class="about-container">
          <div class="about-wrap">
            <h6 class="text-lightgray fw-400">About Us</h6>
          </div>
          <div>
            <h2 class="small font-fahkwang fw-700 about-title aboutext">About Saas Builder</h2>
          </div>
          <div class="about-points">
            <div class="point-mark">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.5857 9.35095L1.01474 0.779733C0.719033 0.64545 0.366184 0.725448 0.160475 0.979728C-0.046663 1.23401 -0.0538057 1.59543 0.143332 1.85685L6.25033 9.99951L0.143332 18.1422C-0.0538057 18.4036 -0.046663 18.7664 0.159046 19.0193C0.297614 19.1921 0.504752 19.285 0.714747 19.285C0.816173 19.285 0.917599 19.2636 1.01331 19.2193L19.5843 10.6481C19.8386 10.5309 20 10.2781 20 9.99951C20 9.72094 19.8386 9.46809 19.5857 9.35095Z"
                  fill="#1E3892" />
              </svg>
            </div>
            <div>
              <h4 class="primary-text fw-400 about-info">
                Saas Builder is a community for software builders to learn and share experiences.
              </h4>
            </div>
          </div>
          <div class="about-points">
            <div class="point-mark">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.5857 9.35095L1.01474 0.779733C0.719033 0.64545 0.366184 0.725448 0.160475 0.979728C-0.046663 1.23401 -0.0538057 1.59543 0.143332 1.85685L6.25033 9.99951L0.143332 18.1422C-0.0538057 18.4036 -0.046663 18.7664 0.159046 19.0193C0.297614 19.1921 0.504752 19.285 0.714747 19.285C0.816173 19.285 0.917599 19.2636 1.01331 19.2193L19.5843 10.6481C19.8386 10.5309 20 10.2781 20 9.99951C20 9.72094 19.8386 9.46809 19.5857 9.35095Z"
                  fill="#1E3892" />
              </svg>
            </div>
            <div>
              <h4 class="primary-text fw-400 about-info">
                The learning experience and shared knowledge is our primary focus.
              </h4>
            </div>
          </div>
          <div>
            <button class="custom-btn orange-btn d-flex align-items-center">
              <h6 class="white">Sign Up for Saas Builder</h6>
              <div class="ms-4 signUp-btn-aero">
                <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.5694 0.281178L22.6788 6.39058C23.0537 6.76548 23.0537 7.37332 22.6788 7.74822L16.5694 13.8576C16.1945 14.2325 15.5867 14.2325 15.2118 13.8576C14.8369 13.4827 14.8369 12.8749 15.2118 12.5L19.6824 8.0294H0.96C0.429806 8.0294 0 7.5996 0 7.0694C0 6.53921 0.429805 6.1094 0.959998 6.1094H19.6824L15.2118 1.63882C14.8369 1.26392 14.8369 0.656081 15.2118 0.281178C15.5867 -0.0937259 16.1945 -0.0937259 16.5694 0.281178Z"
                    fill="white" />
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div class="about-swiper-wrap">
        <div class="swiper" ref="aboutSwiper">
          <div class="swiper-wrap swiper-wrapper">
            <div class="swiper-slide active">
              <div class="about-card">
                <img src="./../assets/images/about-saas/about-1.jpg" alt="About Us" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="about-card">
                <img src="./../assets/images/about-saas/about-2.jpg" alt="About Us" />
              </div>
            </div>
            <div class="swiper-slide">
              <div class="about-card">
                <img src="./../assets/images/about-saas/about-3.jpg" alt="About Us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ABOUT SAAS BUILDER END -->
  </div>
</template>
<script>
import { RouterLink } from "vue-router";
import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  components: {
    RouterLink,
  },
  name: "about-view",
  title: "About",
  mounted() {
    new Swiper(this.$refs.aboutSwiper, {
      loop: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      slidesPerView: 1.2,
      spaceBetween: 15,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2.2,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 1.5,
          spaceBetween: 20,
        },
        1440: {
          slidesPerView: 2.2,
          spaceBetween: 30,
        },
      },
    });
  },
};
</script>
<style>
.mission-wrap {
  padding: 12rem 0;
}

.mission-content-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mission-info {
  width: 453px;
}

.mission-info h6 {
  margin-bottom: 13px;
}

.mission-info h2 {
  margin-bottom: 25px;
}

.mission-info p {
  line-height: 2.5;
}

.mission-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 90px 54px;
  padding-left: 150px;
  width: calc(100% - 453px);
}

.mission-content .page-title-label {
  line-height: 1.9;
}

.mission-icon {
  width: 80px;
  height: 80px;
  background-color: var(--purple);
  border-radius: 50px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mission-icon img {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.explore-techniques {
  color: var(--white-smok);
  padding: 16rem 0 11rem 0;
  background-image: url(./../assets/images/backgrounds/blue-pattern.jpg);
}

.explore-tech-content h2 {
  margin-bottom: 50px;
}

.explore-tech-content .custom-btn {
  margin-top: 80px;
}

/* About saas */

.about-info {
  line-height: 2;
}

.about-points {
  display: flex;
  align-items: flex-start;
}

.point-mark {
  margin-right: 20px;
  line-height: 60px;
}

.about-title {
  margin-top: 13px;
  margin-bottom: 40px;
}

.about-points:not(:last-child) {
  margin-bottom: 35px;
}

.about-img-wrap:not(:last-child) {
  margin-right: 30px;
}

.about-container {
  margin-left: 200px;
}

.about-card {
  min-height: 530px;
  max-height: 530px;
  height: 530px;
  width: 100%;
}

.about-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* style added by RIA */
.mission-wrap {
  position: relative;
  background-color: #f4f4f7;
  background-image: url("../assets/images/backgrounds/about-mission-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  /* margin-top: 100px; */
}

/* .mission-wrap p {
  line-height: 1.5;
} */

.about-saas {
  background-color: #f4f4f7;
  background-image: url("../assets/images/backgrounds/about-sassbuilder-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-saas>* {
  width: 50%;
}

.about-saas-content {
  max-width: 900px;
}

/* style added by RIA */
</style>
