<template>
	<div class="header-spacing">
		<section class="sass-pattern space-120">
			<div class="container">
				<h2 class="font-fahkwang fw-700 mrBottom-50">
					Saas <span class="orange"> Patterns</span>
				</h2>

				<div class="sass-patterns-wrap">
					<div class="patterns-sidebar">
						<div
							class="search-wrap position-relative d-flex align-items-center justify-content-between"
						>
							<input
								type="text"
								class="search-input"
								@input="handleSearch"
								placeholder="Search Pattern"
							/>
							<img
								src="../assets/images/icons/search.svg"
								alt="search"
								class="search-icon"
							/>
							<div>
								<button 
									class="reset-btn custom-btn orange-btn"
									@click="resetFilters"
								>
								Reset
								</button>
							</div>
						</div>
						<div class="pattern-content-card">
							<div class="pattern-content-title">
								<h6>Pattern Types</h6>
							</div>
							<div>
								<!--accordion start-->
								<div class="pattern-accordion">
									<v-row>
										<v-treeview
											ref="patternTree"
											:items="patterns"
											selection-type="leaf"
											return-object
											activatable
											dense
											hoverable
											expand-icon="mdi-chevron-down"
											:active="active"
											:open.sync="patternsOpen"
											@update:active="treeNodeActive"
											@update:open="treeNodeOpen"
											:search="search"
										>
										</v-treeview>
									</v-row>
								</div>
								<!--accordion end-->
								<div
									class="submit-pattern-wrap d-flex align-items-center justify-content-center"
								>
									<button
										class="custom-btn orange-btn d-flex align-items-center justify-content-center w-100"
										@click="submitPatternNavigation()"
									>
										<h6 class="white">Submit a Pattern</h6>
										<div class="ms-4 signUp-btn-aero">
											<svg
												width="23"
												height="15"
												viewBox="0 0 23 15"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M16.5694 0.281178L22.6788 6.39058C23.0537 6.76548 23.0537 7.37332 22.6788 7.74822L16.5694 13.8576C16.1945 14.2325 15.5867 14.2325 15.2118 13.8576C14.8369 13.4827 14.8369 12.8749 15.2118 12.5L19.6824 8.0294H0.96C0.429806 8.0294 0 7.5996 0 7.0694C0 6.53921 0.429805 6.1094 0.959998 6.1094H19.6824L15.2118 1.63882C14.8369 1.26392 14.8369 0.656081 15.2118 0.281178C15.5867 -0.0937259 16.1945 -0.0937259 16.5694 0.281178Z"
													fill="white"
												></path>
											</svg>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>

          <div class="patterns-main-wrapper">
            <div class="patterns-header">
              <h4 class="small primary-text fw-500">SaaS Patterns</h4>
            </div>
            <main class="patterns-wrapper-content">
              <div class="saas-container">
                <div class="saas-wrap">
                          <v-card class="saas-content-wrap"
                              v-if="patternMarkDown === null"
                          >
                            <v-card-text class="text--primary">
                              This will show a brief intro of the patterns page when no pattern is selected
                            </v-card-text>
                          </v-card>
                          <v-card v-if="patternMarkDown !== null"
                                  class="saas-content-wrap pa-3">

                            <div class="saas-title">
                              <h6 class="primary-text font-500 line-height-30">
                                {{ selectedPattern.name }}
                              </h6>
                            </div>

                            <div v-html="md(patternMarkDown)"></div>
                          </v-card>

                </div>



								<div class="ref-wrap">

									<!--<div class="author-container">-->
                          <template
                              v-if="authors !== null"
                          >

                            <v-card
                                v-for="author in authors" :key="author.id"
                            >
                            <v-row >
                              <div class="author-container">
                                <div class="position-relative author">
                                  <div class="author-wrap">
                                    <v-img
                                        class="author-img"
                                        max-height="250"
                                        :alt="author.name"
                                        :src="author.image"
                                    ></v-img>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <h6
                                        class="primary-text font-500 line-height-30 margin-5"
                                    >
                                      Authored By {{ author.name }}
                                    </h6>
                                  </div>
                                  <div>
                                                <!--<span
                                                    class="page-title-label secondary-text line-height-25 font-400 software-title"
                                                >-->
                                                    <div v-html="md(author.bio)"></div>
                                                <!--</span>-->
                                  </div>
                                  <div>
                                                <span
                                                    class="page-title-label line-height-30 font-500 primary-text margin-5"
                                                >
                                                    Follow on:
                                                </span>
                                  </div>
                                  <div class="logo-wrapper">

                                    <a v-bind:href="author.twitter"
                                       target="_blank"
                                       v-if="author.twitter && author.twitter.length>0">
                                      <div class="logo-container me-3">
                                        <div class="logo-wrap">
                                          <img
                                              src="../assets/images/icons/Twitter-logo.svg.png"
                                              alt="twitter"
                                              class="logo"
                                          />
                                        </div>
                                        <div>
                                                            <span
                                                                class="page-title-label font-400 line-height-25 secondary-text mb-0"
                                                            >
                                                                Twitter
                                                            </span>
                                        </div>
                                      </div></a>
                                    <a
                                        v-bind:href="author.linkedin"
                                        target="_blank"
                                        v-if="author.linkedin && author.linkedin.length>0"
                                    >
                                      <div class="logo-container">
                                        <div class="logo-wrap">
                                          <img
                                              src="../assets/images/icons/LI-In-Bug.png"
                                              alt="linkdin"
                                              class="logo"
                                          />
                                        </div>
                                        <div>
                                                            <span
                                                                class="page-title-label font-400 line-height-25 secondary-text mb-0"
                                                            >
                                                                Linkedin
                                                            </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </v-row>
                          </v-card>
                        </template>
									<!--</div>-->
								</div>
							</div>
						</main>
					</div>
					<!-- right side content end-->
				</div>
			</div>
		</section>
	</div>
</template>
<script>

import VueCookies from 'vue-cookies';
import {marked} from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';


let patternsURL = window.location.origin + '/saasbuilder-patterns/';

function setupMarked() {
  marked.setOptions({
    "baseUrl": null,
    "breaks": true,
    "gfm": true,
    "extensions": null,
    "headerIds": true,
    "headerPrefix": "",
    "highlight": function (code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, {language}).value;
    },
    "langPrefix": "hljs language-", // highlight.js css expects a top-level 'hljs' class.
    "mangle": true,
    "pedantic": false,
    "sanitize": false,
    "sanitizer": null,
    "silent": false,
    "smartLists": false,
    "smartypants": false,
    "tokenizer": null,
    "walkTokens": null,
    "xhtml": true
  });

  // Override function
  const renderer = {

    heading(text, level) {
      return `
            <h${level}>
              ${text}
            </h${level}>`;
    },
    list(body) {
      let output = '<ul class="content-list">';
      const regex = /(?:<li>)(.*)(?:<\/li>)/g;
      const matches = body.matchAll(regex);
      for (const match of matches) {
        output += '<li className="d-flex content-list-item">';
        output += '<div>';
        output += '<img src="../assets/images/icons/mark.svg" alt="mark" className="tick-mark" />';
        output += '</div>';
        output += '<div>';
        output += '<span className="page-title-label font-400 line-height-25 secondary-text" >';
        output += match[1];
        output += '</span>';
        output += '</div>';
        output += '</li>';
      }
      output += '</ul>';
      return output;
    }
  };

  marked.use({renderer});
}

setupMarked();

export default {
  data: () => ({
    patterns: [],
    dicPatterns: new Map(),
    patternsOpen: [],
    patternsLastOpen: [],
    patternAllOpened: false,
    categories: [],
    search: null,
    loading: false,
    treeActiveItem: null,
    selectedPattern: null,
    patternMarkDown: null,
    authors: null,
    active: [],
  }),
  watch: {
    search() {
      if (this.loading == false) {
        VueCookies.set('Search', this.search == null ? '' : this.search, "24h");
      }
    }
  },
  methods: {
    md: function (input) {
      if (input === undefined || input === null) {
        return "";
      }
      return marked.parse(input);
    },
    handleSearch(val) {
      // This section handles auto opening of tree leafs when there is a search and keeps a record of the current open leafs
      // if we clear the search the tree goes back to its original state
      if (val) {
        if (!this.patternAllOpened) {
          this.patternsLastOpen = this.patternsOpen;
          this.patternAllOpened = true;
          this.$refs.patternTree.updateAll(true);
        }
      } else {
        this.resetSearch();
      }
    },
    resetSearch() {
      this.$refs.patternTree.updateAll(false);
      this.patternAllOpened = false;
      this.patternsOpen = this.patternsLastOpen;
    },
    addPatternsToDictionary(patterns, parent) {
      patterns.forEach(pattern => {
        pattern.parent = parent;
        this.dicPatterns.set(pattern.id, pattern);
        if (pattern.children !== undefined && pattern.children !== null) {
          this.addPatternsToDictionary(pattern.children, pattern);
        }
      });
    },
    async getPatterns() {
      this.loading = true;
      let url = patternsURL + 'patterns.json';
      await fetch(url)
          .then(response => response.text())
          .then(text => {
                let jPatterns = JSON.parse(text);
                this.addPatternsToDictionary(jPatterns.patterns, null);
                this.patterns = jPatterns.patterns;
              }
          );
      this.loading = false;
    },
    resetFilters() {
      this.search = null;
      this.resetSearch();
    },
    loadUserSettings() {
      this.loading = true;

      let openJSON = VueCookies.get('PatternsOpen');
      if (openJSON !== undefined && openJSON !== null && openJSON.length > 0) {
        let openPatternIds = openJSON;//JSON.parse(openJSON);
        for (let x = 0; x < openPatternIds.length; x++) {
          this.patternsOpen.push(this.dicPatterns.get(openPatternIds[x]));
        }
      }
      let selectedPatternId = VueCookies.get('SelectedPatternID');
      if (selectedPatternId !== undefined && selectedPatternId !== null && selectedPatternId.length > 0) {
        this.active.push(this.dicPatterns.get(selectedPatternId));
      }

      let searchCheck = VueCookies.get('Search');
      if (searchCheck !== undefined && searchCheck !== null && searchCheck.length > 0 && searchCheck != 'null') {
        this.search = searchCheck;
        this.$refs.patternTree.updateAll(true);
      }

      this.loading = false;
    },
    async treeNodeOpen(items) {
      if (this.loading == false) {
        let patternsOpen = [];
        for (let x = 0; x < items.length; x++) {
          patternsOpen.push(items[x].id);
        }
        VueCookies.set('PatternsOpen', JSON.stringify(patternsOpen), "24h");
      }
    },
    async treeNodeActive(node) {
      let item = node !== undefined && node !== null && node.length > 0 ? node[0] : undefined;
      if (item !== undefined && item !== null && item.id !== null && item.path !== null && item.path.length > 0) {
        this.patternsOpen.push(item);
        this.selectedPattern = item;
        VueCookies.set('SelectedPatternID', this.selectedPattern.id, "24h");
        //grab the pattern document to show
        let rootURL = patternsURL + this.selectedPattern.path + "/";
        let fetchURL = patternsURL + this.selectedPattern.path + "/pattern.md";
        fetch(fetchURL)
            .then(
                (response) => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                  }
                return response.text();
              })
            .then((text) => {
              this.patternMarkDown = text.replaceAll(/(\.\/.*?\.(?:jpg|jpeg|png|gif|svg|tiff|ico))/g, (match) => {
                return rootURL + match;
              });
            }).catch(err => {
              console.log("Error fetching pattern: " + err);
        });


        fetch(patternsURL + this.selectedPattern.path + "/authors.json")
            .then(response => response.text())
            .then(text => {
              let authorJSON = text.replaceAll(/(\.\/.*?\.(?:jpg|jpeg|png|gif|svg|tiff|ico))/g, (match) => {
                return rootURL + match;
              });
              this.authors = JSON.parse(authorJSON).authors;
            }).catch(err => {
          console.log("Error fetching authors: " + err);
        });
      } else {
        this.selectedPattern = null;
        this.patternMarkDown = null;
        this.authors = null;
      }
    },
    getPatternFullPath(pattern) {
      if (pattern.parent !== null) {
        return this.getPatternFullPath(this.dicPatterns.get(pattern.parent.id)) + '\\' + pattern.name;
      }
      return pattern.name;
    },
    submitPatternNavigation() {
      window.open(process.env.VUE_APP_PATTERNS_SUBMIT_URL, '_blank');
    }
  },
  async created() {
    await this.getPatterns().then(() => {
      this.loadUserSettings();
    });
  }
}
</script>
<style>
.sass-pattern {
	position: relative;
	/* margin-top: 100px; */
}
.sass-pattern::after {
	position: absolute;
	left: 0;
	top: -50px;
	background-image: url("../assets/images/backgrounds/saass-patterns-bg.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	display: block;
	content: "";
	height: 100%;
	z-index: -1;
}
.sass-patterns-wrap {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	overflow-y: hidden;
}

.patterns-sidebar {
	max-width: 400px;
	width: 100%;
}

.patterns-main-wrapper {
	width: calc(100% - 400px);
	display: flex;
	flex-direction: column;
	margin-left: 25px;
}

.patterns-header {
	height: 60px;
	display: flex;
	align-items: center;
}

.patterns-wrapper-content {
	height: 100%;
}

/* */
.search-wrap {
	width: 100%;
	height: 50px;
}
.search-input {
	width: 300px;
	height: 100%;
	font-size: 14px;
	padding: 15px 15px 15px 40px;
	border-width: 0;
	/* background-color: var(--white); */
	border: 1px solid transparent;
}

.search-input:focus-visible,
.search-input:active {
	/* border: 1px solid black; */
	border-width: 1px;
	outline-offset: 0px;
	outline: none;
	border: 1px solid gray;
}

.search-icon {
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
}
.pattern-content-card {
	border: 1px solid #bbc0cd;
	margin-top: 10px;
}
.pattern-content-title {
	padding: 15px 20px;
	background-color: rgb(130 139 163 / 10%);
}
.pattern-content-title h6 {
	line-height: 1.5;
}
.pattern-content-wrap {
	/* padding: 15px; */
	background-color: var(--white);
}

.pattern-accordion .card {
	border-width: 0;
	border-radius: 0 !important;
	/* border-bottom: 1px solid black; */
}
.pattern-accordion .card:last-child {
	border-bottom: none;
}
.pattern-accordion .card-header {
	border: none;
	padding: 0;
	border-radius: none;
	background-color: transparent;
}
.pattern-accordion .card-body {
	padding: 0;
}
.card-body.content-space {
	padding: 0 0 0 40px;
}
.space-none {
	padding: 0;
}

.pattern-accordion .card-header button {
	font-size: 20px;
	font-weight: 500;
	width: 100%;
	text-align: start;
	background: transparent;
	border: none;
	padding: 15px 0px;
	/* padding-left: 15px; */
	display: flex;
	align-items: center;
	justify-content: start;
}
.pattern-accordion .card-header button:hover,
.pattern-accordion .card-header button:focus {
	background-color: transparent;
}
.play-icon {
	margin-right: 15px;
}

.pattern-accordion .toggle-accordion-icon {
	width: 16px;
	height: 16px;
	position: relative;
	line-height: 1.2;
	display: inline-flex;
	border-radius: 100%;
	border: 1px solid var(--orange);
}
.pattern-accordion .toggle-accordion-icon::before,
.pattern-accordion .toggle-accordion-icon::after {
	content: "";
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 9px;
	height: 2px;
	display: block;
	margin: auto;
	position: absolute;
	background-color: var(--orange);
	transform-origin: 50% 50%;
	transition: all 0.25s;
}
.pattern-accordion .card-header button.collapsed .toggle-accordion-icon::after {
	transform: rotate(-90deg);
	opacity: 1;
}
.submit-pattern-wrap {
	padding: 15px;
}

.pattern-accordion .card-header.header-accordion-main-custom {
	border-bottom: 1px solid #eff0f2;
}
.content-test {
	padding-left: 40px;
	padding-top: 15px;
}
.content-test:last-child {
	padding-bottom: 15px;
}
.content-test.space-identity {
	padding-left: 30px;
}
.content-test.space-identity:last-child {
	padding-bottom: 15px;
}
.content-test p {
	padding-bottom: 0;
	margin-bottom: 0;
	line-height: 1.3;
}
.sepration {
	padding: 0 15px;
	border-bottom: 5px solid #f3f4f6;
}
.sepration:last-child {
	border-bottom: none;
}
/* */

/* line clamp start */

.qn-line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.ans-line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/* line clamp end */

/* saas start */

.saas-wrap {
	padding: 20px 20px 16px 20px;
}

.saas-container {
	border: 1px solid #bbc0cd;
	background-color: white;
	/* max-width: 1095px;
    width: 100%;
    margin: 30px; */
}

.saas-title {
	margin-bottom: 12px;
}

.avail-zone {
	max-width: 320px;
	max-height: 260px;
	margin-right: 10px;
}
.avail-zone:last-child {
	margin-right: 0;
}
.avail-zone-img {
	width: 100%;
	height: 100%;
}

.separator {
	width: 100%;
	margin-top: 0;
	margin-bottom: 15px;
}

hr:not([size]) {
	height: 5px;
}

.saas-content-wrap {
	padding: 0px 18.5px 27px 20px;
}

.content-list {
	list-style: none;
	padding: 0;
}

.tick-mark {
	margin-right: 10px;
}

.content-title {
	margin-bottom: 10px;
}

.core-wrap {
	margin-bottom: 20px;
}

.content-list-item:not(:last-child) {
	margin-bottom: 15px;
}

.ref-wrap {
	padding: 5px 24px 21px 16px;
}

.reference-wrap {
	width: 140px;
	height: 42px;
	background-color: var(--orange);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -24px;
	z-index: 2;
	margin-left: 25px;
}

.aws-wrap {
	padding: 36px 25px 19px 25px;
}

.aws-container {
	background-color: var(--ref-back);
	position: relative;
}

.reference-container {
	margin-bottom: 15px;
}

.author-wrap {
	max-width: 150px;
	width: 150px;
	max-height: 170px;
	height: 170px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.author-img {
	width: 100%;
	object-fit: cover;
	height: 100%;
}

.author-title {
	width: 100px;
	height: 42px;
	background-color: var(--orange);
	display: flex;
	align-items: center;
	justify-content: center;
	top: 147px;
	position: absolute;
	left: 25px;
}

.logo-wrap {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	flex: 0 0 auto;
}

.logo-container {
	width: 130px;
	border-radius: 25px;
	height: 50px;
	background-color: var(--ref-back);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 21px 5px 5px;
}

.logo-container:not(:last-child) {
	margin-right: 10px;
}

.author-container {
	display: flex;
  margin: 5px;
  padding-top: 5px;
}

.logo-wrapper {
	display: flex;
}

.margin-5 {
	margin-bottom: 5px;
}

.software-title {
	margin-bottom: 25px;
}

.author {
	margin-right: 15px;
}

.logo {
	width: 24px;
	height: 24px;
}

/* saas end */
/* 
.pattern-accordion .header-accordion-main-custom .card-header button {
  color: red;
} */
</style>
