<template>

  <v-row>
    <v-col
        cols="4"
    >
      <v-card
      >
      <v-text-field
          label="Pattern RAW URL"
          v-model="patternURL"
          persistent-hint
          class="ma-2"
      ></v-text-field>
      <v-btn
          class="ma-2"
          outlined
          color="indigo"
          @click="loadPattern()"
      >
        Load Pattern
      </v-btn>

      </v-card>
      <v-divider

          class="mb-6"
      ></v-divider>
      <v-textarea
          label=""
          name="markdownInput"
          auto-grow
          rows="30"
          full-width
          dense
          outlined
          :value="patternMarkDownText"
          @input="markdownChanged('input', $event)"
      ></v-textarea>
      <v-textarea
          label=""
          name="authorsInput"
          auto-grow
          rows="10"
          full-width
          dense
          outlined
          :value="authorsJSON"
          @input="authorsChanged('input', $event)"
      ></v-textarea>
    </v-col>
    <v-col
        cols="12"
        md="8"
    >
      <v-card
          v-if="patternMarkDown === null"
      >
        <v-card-text class="text--primary">
          This will preview your pattern to allow for adjustments prior to submission
        </v-card-text>
      </v-card>
      <v-card v-if="patternMarkDown !== null"
        class="pa-3">
        <div v-html="md(patternMarkDown)"></div>
      </v-card>

      <v-divider

          class="mb-6"
      ></v-divider>
      <template
          v-if="authors !== null"
      >
        <v-card
            v-for="author in authors" :key="author.id"
            class="mx-auto mb-3 pa-2"
        >
          <v-row>
            <v-col
                cols="2"
            >
              <v-img
                  class="mt-3 mb-3 ml-3 mr-3 rounded-lg"
                  max-height="250"
                  :alt="author.name"
                  :src="author.image"
              ></v-img>
            </v-col>
            <v-col>
              <v-card-title class="text--primary">
                Authored By {{ author.name }}
              </v-card-title>
              <v-card-text>
                <div v-html="md(author.bio)"></div>
              </v-card-text>
              <v-chip
                  class="ma-2"
                  color="cyan"
                  link
                  text-color="white"
                  v-if="author.twitter && author.twitter.length>0"
                  small
                  target="_blank"
                  :href="author.twitter"
              >
                <v-icon left>
                  mdi-twitter
                </v-icon>
                Follow on Twitter
              </v-chip>
              <v-chip
                  class="ma-2"
                  color="cyan"
                  link
                  text-color="white"
                  v-if="author.linkedin && author.linkedin.length>0"
                  small
                  target="_blank"
                  :href="author.linkedin"
              >
                <v-icon left>
                  mdi-linkedin
                </v-icon>
                Follow on LinkedIn
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>


<style>
.hljs {
  margin-bottom: 16px;
}
.pattern-image {
  max-width: 100%;
}
</style>
<script>

import {marked} from 'marked';
import VueCookies from 'vue-cookies';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';


function setupMarked() {
  marked.setOptions({
    "baseUrl": null,
    "breaks": true,
    "gfm": true,
    "extensions": null,
    "headerIds": true,
    "headerPrefix": "",
    "highlight": function(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, { language }).value;
    },
    "langPrefix": "hljs language-", // highlight.js css expects a top-level 'hljs' class.
    "mangle": true,
    "pedantic": false,
    "sanitize": false,
    "sanitizer": null,
    "silent": false,
    "smartLists": false,
    "smartypants": false,
    "tokenizer": null,
    "walkTokens": null,
    "xhtml": true
  });

  // Override function
  const renderer = {
    heading(text, level) {
      return `
            <h${level} style="margin-top: 24px; margin-bottom: 16px;">
              ${text}
            </h${level}>`;
    }
  };

  marked.use({ renderer });
}
setupMarked();

export default {
  data: () => ({
    patternURL: '',
    patternMarkDown: null,
    patternMarkDownText: '',
    loading: false,
    authors: null,
    authorsJSON: '',
  }),
  watch: {
    patternMarkDownText() {
      this.updateMarkDown();
    },
    authorsJSON() {
      this.updateAuthors();
    }
  },
  methods: {
    loadPattern() {
      if (this.patternURL !== null && this.patternURL.length > 0) {
        if (this.patternURL.indexOf("github.com") > 0) {
          this.patternURL = this.patternURL.replace("github.com", "raw.githubusercontent.com").replace("/tree", "").replace("/blob", "");
        }
        if (this.patternURL.endsWith("pattern.md")) {
          this.patternURL = this.patternURL.replace("pattern.md","");
        }
        if (!this.patternURL.endsWith("/")) {
          this.patternURL = this.patternURL + "/";
        }
        VueCookies.set('PatternURL', this.patternURL, "24h");
        fetch(this.patternURL + "pattern.md")
            .then(response => response.text())
            .then(text => this.patternMarkDownText = text);

        fetch(this.patternURL + "authors.json")
            .then(response => response.text())
            .then(text => this.authorsJSON = text);
      } else {
        this.patternMarkDown = null;
      }
    },
    updateMarkDown() {
      if (this.loading) {
        return;
      }
      if (this.patternMarkDownText !== null && this.patternMarkDownText.length > 0) {
        sessionStorage.PatternMarkDownText = this.patternMarkDownText;
        if (this.patternURL !== null && this.patternURL !== undefined) {
          this.patternMarkDown = this.patternMarkDownText.replaceAll(/(\.\/.*?\.(?:jpg|jpeg|png|gif|svg|tiff|ico))/g, (match) => {
            return this.patternURL + match;
          });
        }
      } else {
        sessionStorage.PatternMarkDownText = null;
        this.patternMarkDown = null;
      }
    },
    updateAuthors() {
      if (this.loading) {
        return;
      }
      if (this.authorsJSON !== null && this.authorsJSON.length > 0) {
        sessionStorage.Authors = this.authorsJSON;
        if (this.patternURL !== null && this.patternURL !== undefined) {
          let tmpAuth = this.authorsJSON.replaceAll(/(\.\/.*?\.(?:jpg|jpeg|png|gif|svg|tiff|ico))/g, (match) => {
            return this.patternURL + match;
          });
          this.authors = JSON.parse(tmpAuth).authors;
        }
      } else {
        this.authors = null;
      }
    },
    markdownChanged(type, content) {
      this.patternMarkDownText = content;
    },
    authorsChanged(type, content) {
      this.authorsJSON = content;
    },
    md: function (input) {
      if (input === undefined || input === null) {
        return "";
      }
      return marked.parse(input);
    }
  },
  async created() {
    this.loading = true;
    let text = sessionStorage.PatternMarkDownText;
    if (text !== undefined && text !== null && text.length > 0) {
      this.patternMarkDownText = text;
    }
    text = sessionStorage.Authors;
    if (text !== undefined && text !== null && text.length > 0) {
      this.authors = JSON.parse(text);
    }
    let url = VueCookies.get('PatternURL');
    if (url !== undefined && url !== null && url.length > 0) {
      this.patternURL = url;
    }
    let queryPatternURL = this.$route.query.pattern;
    if (queryPatternURL !== undefined && queryPatternURL !== null && queryPatternURL.length > 0) {
      this.patternURL = queryPatternURL;
    }
    this.loading = false;
    if (this.patternURL !== undefined && this.patternURL !== null && this.patternURL.length > 0) {
      this.updateMarkDown();
    }
  }
}
</script>

