<template>
  <div class="header-spacing">
    <section class="QuestionView">
      <div class="container">
        <h3 class="small fw-500 primary-text">
          Does Snowflake require less people to mantain than Azure Synapse DataWarehouse?
        </h3>
        <div class="d-flex flex-wrap ques-ans-tags">
          <div class="d-flex align-items-center justify-content-center tagname bg-white">
            <span class="secondary-text fw-400 h6">#cloud</span>
          </div>
          <div class="d-flex align-items-center justify-content-center tagname bg-white">
            <span class="secondary-text fw-400 h6">#snowflake-cloud-data-platform</span>
          </div>
          <div class="d-flex align-items-center justify-content-center tagname bg-white">
            <span class="secondary-text fw-400 h6">#data-warehouse</span>
          </div>
          <div class="d-flex align-items-center justify-content-center tagname bg-white">
            <span class="secondary-text fw-400 h6">#provider</span>
          </div>
          <div class="d-flex align-items-center justify-content-center tagname bg-white">
            <span class="secondary-text fw-400 h6">#azure-synapse</span>
          </div>
        </div>
        <div class="white-border"></div>
        <div class="quesans-profilewrap">
          <!-- profile-1 -->
          <div class="user-profile d-flex align-items-start">
            <div class="user-img">
              <img src="../assets/images/avatars/avatar8.jpg" alt="avatar" />
            </div>
            <div class="user-profile-content w-100 pt-3">
              <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div>
                  <h5 class="mb-2 primary-text fw-500">Jonathan Doe</h5>
                  <span class="secondary-text fw-400">Developer</span>
                </div>
                <div>
                  <span class="h5 d-inline-block primary-text fw-500 mb-0">3d ago</span>
                </div>
              </div>
              <div class="mt-5">
                <p class="secondary-text h6 fw-400 mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip.
                </p>
                <p class="secondary-text h6 fw-400">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </p>
              </div>
              <div class="d-flex align-items-center justify-content-between social-likes flex-wrap">
                <div class="d-flex align-items-center justify-content-start flex-wrap">
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/redheart.svg" alt="" />
                    <span class="primary-text fw-400 h6 mb-0">1.3 k</span>
                  </a>
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/connections.svg" alt="" />
                    <span class="primary-text fw-400 h6 mb-0">120</span>
                  </a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/replay.svg" alt="" class="me-3" />
                    <span class="primary-text fw-400 h6 mb-0">Replay</span>
                  </a>
                  <a href="javascript:void(0)">
                    <img src="../assets/images/icons/dots.svg" alt="" />
                  </a>
                </div>
              </div>
              <div class="quesans-votes-wrap">
                <div class="d-flex justify-content-start flex-wrap">
                  <div class="d-flex flex-column align-items-center votersList">
                    <h4 class="small primary-text fw-500 mb-2">0</h4>
                    <p class="secondary-text fw-400 mb-0">Votes</p>
                  </div>
                  <div class="d-flex flex-column align-items-center votersList">
                    <h4 class="small primary-text fw-500 mb-2">5</h4>
                    <p class="secondary-text fw-400 mb-0">Answers</p>
                  </div>
                  <div class="d-flex flex-column align-items-center votersList">
                    <h4 class="small primary-text fw-500 mb-2">10</h4>
                    <p class="secondary-text fw-400 mb-0">Views</p>
                  </div>
                  <div class="d-flex flex-column align-items-center votersList" >
                    <h4 class="small primary-text fw-500 mb-2">1.3k</h4>
                    <p class="secondary-text fw-400 mb-0">Likes</p>
                  </div>
                  <div class="d-flex flex-column align-items-center votersList">
                    <h4 class="small primary-text fw-500 mb-2">1d ago</h4>
                    <p class="secondary-text fw-400 mb-0">Created</p>
                  </div>
                  <div class="d-flex flex-column align-items-center votersList">
                    <h4 class="small primary-text fw-500 mb-2">4h ago</h4>
                    <p class="secondary-text fw-400 mb-0">Last Replay</p>
                  </div>
                </div>
              </div>
              <div class="grayBorderClass"></div>
            </div>
          </div>
          <!-- profile-2 -->
          <div class="user-profile d-flex align-items-start">
            <div class="user-img">
              <img src="../assets/images/avatars/avatar9.jpg" alt="avatar" />
            </div>
            <div class="user-profile-content w-100 pt-3">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <h5 class="mb-2 primary-text fw-500">Mikhail Mark</h5>
                  <span class="secondary-text fw-400">Developer</span>
                </div>
              </div>
              <div class="mt-5">
                <p class="secondary-text h6 fw-400 mb-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat
                </p>
              </div>
              <div class="d-flex align-items-center justify-content-between social-likes mb-0 flex-wrap">
                <div class="d-flex align-items-center justify-content-start flex-wrap">
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/redheart.svg" alt="" />
                    <span class="primary-text fw-400 h6 mb-0">1.3 k</span>
                  </a>
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/connections.svg" alt="" />
                    <span class="primary-text fw-400 h6 mb-0">120</span>
                  </a>
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/edit.svg" alt="" />
                    <span class="primary-text fw-400 h6 mb-0">Edit</span>
                  </a>
                </div>
                <div class="d-flex align-items-center justify-content-start">
                  <a href="javascript:void(0)" class="d-flex align-items-center social-img">
                    <img src="../assets/images/icons/replay.svg" alt="" class="me-3" />
                    <span class="primary-text fw-400 h6 mb-0">Replay</span>
                  </a>
                  <a href="javascript:void(0)">
                    <img src="../assets/images/icons/dots.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="features-icon-wrap d-flex justify-content-start flex-wrap">
          <a href="javascript:void(0)" class="features-icon d-flex align-items-center justify-content-center bg-white">
            <img src="../assets/images/icons/setting.svg" alt="" />
          </a>
          <a href="javascript:void(0)" class="features-icon d-flex align-items-center justify-content-center bg-white">
            <img src="../assets/images/icons/connections.svg" class="me-3" alt="" />
            <span class="secondary-text fw-500">Share</span>
          </a>
          <a href="javascript:void(0)" class="features-icon d-flex align-items-center justify-content-center bg-white">
            <img src="../assets/images/icons/bookmark.svg" class="me-3" alt="" />
            <span class="secondary-text fw-500">Bookmark</span>
          </a>
          <a href="javascript:void(0)" class="features-icon d-flex align-items-center justify-content-center bg-white">
            <img src="../assets/images/icons/flag.svg" class="me-3" alt="" />
            <span class="secondary-text fw-500">Flag</span>
          </a>
        </div>
        <div class="suggested-topics-wrap">
          <h3 class="small primary-text fw-500">Suggested Topics</h3>
          <div class="d-flex align-items-start justify-content-between flex-xl-nowrap flex-wrap">
            <div class="allQuestions-sidebar">
              <div class="qnContainer" v-for="qn in questions" :key="qn.id">
                <div class="d-flex">
                  <div>
                    <h4 class="small fw-400 primary-text me-4">{{ qn.id }}.</h4>
                  </div>
                  <div class="content-wrap">
                    <router-link to="/question">
                      <h4 class="small fw-400 primary-text shortTextDesc mb-3">
                        {{ qn.question }}
                      </h4>
                      <p class="shortTextDesc secondary-text fw-400 h6 mb-4">
                        {{ qn.answer }}
                      </p>
                    </router-link>
                    <div class="d-flex flex-wrap">
                      <div class="d-flex align-items-center justify-content-center tag-background p-3 qn-tag">
                        <span class="secondary-text fw-400 small-head">#python</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-center tag-background p-3 qn-tag">
                        <span class="secondary-text fw-400 small-head">#azure</span>
                      </div>
                      <div class="d-flex align-items-center justify-content-center tag-background p-3 qn-tag">
                        <span class="secondary-text fw-400 small-head">#oauth-2.0</span>
                      </div>
                    </div>
                    <div class="grayBorderClass"></div>
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                      <div class="d-flex justify-content-start order-2 order-sm-1">
                        <div class="d-flex flex-column align-items-center votersList">
                          <h4 class="small primary-text fw-500 mb-2">0</h4>
                          <p class="secondary-text fw-400 mb-0">Votes</p>
                        </div>
                        <div class="d-flex flex-column align-items-center votersList">
                          <h4 class="small primary-text fw-500 mb-2">5</h4>
                          <p class="secondary-text fw-400 mb-0">Answers</p>
                        </div>
                        <div class="d-flex flex-column align-items-center votersList">
                          <h4 class="small primary-text fw-500 mb-2">10</h4>
                          <p class="secondary-text fw-400 mb-0">Views</p>
                        </div>
                      </div>
                      <div class="userProfile d-flex align-items-center order-1 order-sm-2">
                        <div class="user-img me-3">
                          <img src="./../assets/images/avatars/avatar1.jpg" alt="user" />
                        </div>
                        <div class="d-flex flex-column">
                          <span class="primary-text fw-400 mb-2 word-break">Leslie Alexander</span><span
                            class="d-inline-block secondary-text small-content fw-400">5:20 PM 22 Aug, 2022</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="group-container">
              <div class="group-header d-flex align-items-center justify-content-between">
                <h6 class="primary-text fw-500 text-capitalize">Groups</h6>
                <a href="javascript:void(0)" class="secondary-text fw-400 small-head">See all</a>
              </div>
              <div class="groupsWrapPattern">
                <div class="group-content-wrap">
                  <div class="testWrap">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                      <div class="d-flex align-items-center">
                        <div class="grouplogo google-bg">
                          <img src="../assets/images/icons/google.svg" alt="google" />
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fw-500 primary-text mb-2 text-break">Google Team</p>
                          <span class="secondary-text fw-500 small-head">25k Members</span>
                        </div>
                      </div>
                      <div>
                        <a href="javascript:void(0)" class="fw-500 primary-text join-btn">Join</a>
                      </div>
                    </div>
                    <p class="mb-0 secondary-text small-content fw-400">
                      GitLab is the open DevOps platform, delivered as a single application. Our open source
                    </p>
                  </div>
                </div>
                <div class="group-content-wrap">
                  <div class="testWrap">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                      <div class="d-flex align-items-center">
                        <div class="grouplogo google-bg">
                          <img src="../assets/images/icons/gitlab.svg" alt="gitlab" />
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fw-500 primary-text mb-2 text-break">Gitlab</p>
                          <span class="secondary-text fw-500 small-head">12k Members</span>
                        </div>
                      </div>
                      <div>
                        <a href="javascript:void(0)" class="fw-500 primary-text join-btn">Join</a>
                      </div>
                    </div>
                    <p class="mb-0 secondary-text small-content fw-400">
                      Twilio has democratized channels like voice, text, chat, video, and email by virtualizing the
                    </p>
                  </div>
                </div>
                <div class="group-content-wrap">
                  <div class="testWrap">
                    <div class="d-flex align-items-center justify-content-between mb-4">
                      <div class="d-flex align-items-center">
                        <div class="grouplogo google-bg">
                          <img src="../assets/images/icons/wso2.svg" alt="wso2" />
                        </div>
                        <div class="d-flex flex-column">
                          <p class="fw-500 primary-text mb-2 text-break">WSO2</p>
                          <span class="secondary-text fw-500 small-head">25k Members</span>
                        </div>
                      </div>
                      <div>
                        <a href="javascript:void(0)" class="fw-500 primary-text join-btn">Join</a>
                      </div>
                    </div>
                    <p class="mb-0 secondary-text small-content fw-400">
                      WSO2 solutions give enterprises the flexibility to deploy applications and services on-premises,
                      on
                      private or public clouds, or in
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "question-view",
  title: "Question View",
  data: function () {
    return {
      questions: [
        {
          id: 1,
          question: "How to generate OAuth 2.0 token using TOTP?",
          answer: `
        Have this code in python: import pyotp totp = pyotp.TOTP('secret') otp =
        totp.now() How can I use otp generated above as a request parameter in the below API call to
        retrieve the OAuth2...`,
        },
        {
          id: 2,
          question: "How to call function after executing dialog functionality in angular 10?",
          answer: `
 Below is my code this.openEditBookmarkDialog('', ''); this.initializeJSONnOBJECT(tmpElem); Here
 what I want first open dialog openEditBookmarkDialog(which will automatically call functions...`,
        },
      ],
    };
  },
};
</script>
<style>
.QuestionView {
  position: relative;
  padding: 12rem 0 7rem;
  background: #f4f4f7 url("../assets/images/backgrounds/saas-builder-bg-patern.png") no-repeat top left;
  width: 100%;
  height: 100%;
}

.QuestionView h3.small {
  margin-bottom: 25px;
}

.ques-ans-tags .tagname {
  padding: 15px 20px;
}

.ques-ans-tags .h6 {
  line-height: 25px;
  margin-bottom: 0;
}

.tagname:not(:last-child) {
  margin-right: 10px;
}

.white-border {
  width: 100%;
  height: 2px;
  background-color: var(--white);
  margin: 25px 0;
}

.quesans-profilewrap {
  border: 1px solid #bbc0cd;
  background-color: var(--white);
  padding: 25px 50px 42px 25px;
}

.user-profile .user-img {
  border-radius: 40px;
  width: 70px;
  height: 70px;
  margin-right: 20px;
}

.user-profile .user-img img {
  border-radius: 40px;
}

.social-likes {
  margin: 20px 0 40px 0;
}

.social-img:not(:last-child) {
  margin-right: 30px;
}

.social-img img {
  margin-right: 10px;
}

.quesans-votes-wrap {
  background: rgb(130, 139, 163, 0.1);
  display: flex;
  align-items: center;
  padding: 25px 45px;
  max-width: 700px;
  flex-wrap: wrap;
  height: 100%;
}

.quesans-votes-wrap .votersList:not(:last-child) {
  margin-right: 40px;
}

.user-profile-content .grayBorderClass {
  margin: 45px 0 0;
}

.user-profile:not(:last-child) {
  margin-bottom: 30px;
}

.features-icon-wrap {
  margin-top: 20px;
}

.features-icon {
  padding: 18px 20px;
  border: 1px solid #bbc0cd;
}

.features-icon:not(:last-child) {
  margin-right: 10px;
}

.suggested-topics-wrap {
  margin-top: 40px;
}

.suggested-topics-wrap h3 {
  margin-bottom: 25px;
}

.qnContainer:not(:last-child) {
  margin-bottom: 15px;
}
</style>
